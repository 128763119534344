/**
 * 将像素值转换为基于当前窗口宽度的响应式像素值。
 *
 * @param {number} px - 需要转换的像素值。
 * @param {number} [designWidth=1920] - 设计稿的宽度，默认为 1920px。
 * @returns {number} - 转换后的响应式像素值，保留三位小数。
 */
export const pxToResponsivePx = (px, designWidth = 1920) => {
  // 获取当前窗口的宽度，如果无法获取则使用文档根元素或 body 的宽度
  const clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  // 如果无法获取窗口宽度，则直接返回原始像素值
  if (!clientWidth) return px;
  // 计算当前窗口宽度与设计稿宽度的比例
  const scale = clientWidth / designWidth;
  // 将像素值乘以比例，并保留三位小数后转换为数字类型返回
  return Number((px * scale).toFixed(3));
};
